var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-chat-bubble" } }),
          _c("h5", { staticClass: "d-inline ml-2" }, [_vm._v("Message List")]),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "primary", to: { name: "New Message" } } },
                [
                  _c("CIcon", { attrs: { name: "cil-plus", size: "lg" } }),
                  _c("strong", [_vm._v("New")]),
                  _vm._v(" Message "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _vm.mounting
            ? _c(
                "div",
                { staticClass: "mb-4" },
                [_c("CSpinner", { attrs: { color: "primary" } })],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "CCard",
                    {
                      staticClass: "filters",
                      attrs: { "accent-color": "warning" },
                    },
                    [
                      _c(
                        "CCardHeader",
                        {
                          staticClass: "text-warning shadow-none card-header",
                          attrs: { role: "button" },
                          on: { click: _vm.resetFilters },
                        },
                        [
                          _c(
                            "strong",
                            { staticClass: "m-0" },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: `cil-filter${
                                    _vm.$store.state.filterShow ? "-x" : ""
                                  }`,
                                },
                              }),
                              _vm._v(" Filters"),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-header-actions" },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: `cil-chevron-${
                                    _vm.$store.state.filterShow
                                      ? "bottom"
                                      : "top"
                                  }`,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "CCollapse",
                        { attrs: { show: _vm.$store.state.filterShow } },
                        [
                          _c("CCardBody", { staticClass: "p-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-md-row flex-column",
                                staticStyle: { gap: "0.5rem" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-grow-1" },
                                  [
                                    _c("v-select", {
                                      staticClass: "v-select-filter",
                                      attrs: {
                                        placeholder: "Select restaurant..",
                                        options: _vm.allRestaurants,
                                        loading:
                                          _vm.loading &&
                                          _vm.allRestaurants.length <= 0,
                                      },
                                      on: { input: _vm.restaurantFilter },
                                      model: {
                                        value: _vm.selectedRestaurant,
                                        callback: function ($$v) {
                                          _vm.selectedRestaurant = $$v
                                        },
                                        expression: "selectedRestaurant",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("CDataTable", {
                    staticClass: "table-messages",
                    attrs: {
                      striped: "",
                      hover: "",
                      items: _vm.loadedItems,
                      fields: _vm.fields,
                      sorter: { external: true, resetable: false },
                      itemsPerPageSelect: {
                        external: true,
                        values: [5, 15, 25, 50, 100, 250, 500],
                      },
                      "items-per-page": _vm.itemsPerPage,
                      loading: _vm.loading,
                      noItemsView: {
                        noResults: "No filtering results are available!",
                        noItems: "No messages found!",
                      },
                      "clickable-rows": "",
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "row-clicked": _vm.rowClicked,
                      "update:sorter-value": _vm.sorterValue,
                      "pagination-change": _vm.paginationChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              _c("strong", [
                                _vm._v(_vm._s(item.title[_vm.defaultLang])),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "segment",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.segment) +
                                    " (" +
                                    _vm._s(item.users_count) +
                                    ")"
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "channels",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-gap-1" },
                                _vm._l(item.channels.split(","), function (ch) {
                                  return _c(
                                    "CBadge",
                                    { attrs: { color: "light" } },
                                    [_vm._v(" " + _vm._s(ch) + " ")]
                                  )
                                }),
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "voucher",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("span", [
                                  _vm._v(_vm._s(item.voucher?.code)),
                                ]),
                                _c("cite", { staticClass: "small" }, [
                                  _vm._v(_vm._s(item.voucher?.name)),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "inbox",
                        fn: function ({ item }) {
                          return [
                            _c("td", { staticClass: "text-center" }, [
                              item.inbox
                                ? _c("span", [_vm._v("✅")])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "created_at",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    new Date(item.created_at).toLocaleString()
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "scheduled_time",
                        fn: function ({ item }) {
                          return [
                            _c("td", [
                              item.scheduled_time
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        new Date(
                                          item.scheduled_time
                                        ).toLocaleString()
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "actions",
                        fn: function ({ item, index }) {
                          return [
                            _c(
                              "td",
                              [
                                _c(
                                  "CButton",
                                  {
                                    directives: [
                                      {
                                        name: "c-tooltip",
                                        rawName: "v-c-tooltip",
                                        value: {
                                          html: true,
                                          content: "Duplicate",
                                        },
                                        expression:
                                          "{\n                html: true,\n                content: 'Duplicate'\n              }",
                                      },
                                    ],
                                    attrs: {
                                      size: "sm",
                                      color: "info",
                                      shape: "pill",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.duplicate(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-clone" },
                                    }),
                                  ],
                                  1
                                ),
                                item.is_deletable === true
                                  ? [
                                      item.deleting
                                        ? _c("CSpinner", {
                                            staticClass: "ml-3",
                                            attrs: {
                                              size: "sm",
                                              color: "danger",
                                            },
                                          })
                                        : _c(
                                            "CButton",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                size: "sm",
                                                color: "danger",
                                                variant: "ghost",
                                                shape: "pill",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: { name: "cil-trash" },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
          _c(
            "CCard",
            { staticClass: "actions sticky-bottom" },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap align-items-center",
                    staticStyle: { gap: "0.75rem" },
                  },
                  [
                    _c("div", [
                      _c("h5", { staticClass: "mt-1" }, [
                        _vm._v("Total: "),
                        _c(
                          "span",
                          { staticClass: "d-inline count bg-primary pb-1" },
                          [_vm._v(_vm._s(_vm.total))]
                        ),
                      ]),
                    ]),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          { staticClass: "ml-auto" },
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "center",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "modal-message",
          attrs: {
            title: _vm.modalTitle,
            show: _vm.showMessage,
            closeOnBackdrop: false,
            size: "lg",
            color: "dark",
          },
          on: {
            "update:show": function ($event) {
              _vm.showMessage = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.showMessage = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.selectedItem
            ? [
                _vm.showMessage
                  ? _c(
                      "CTabs",
                      {
                        attrs: { "active-tab": _vm.activeTab },
                        on: {
                          "update:activeTab": function ($event) {
                            _vm.activeTab = $event
                          },
                          "update:active-tab": function ($event) {
                            _vm.activeTab = $event
                          },
                        },
                      },
                      [
                        _c("CTab", { attrs: { title: "Stats" } }, [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "msg-stats list-group list-group-flush",
                            },
                            [
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.selectedItem.segment) + ":"
                                  ),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedItem.users_count)
                                ),
                              ]),
                              _vm.selectedItem.channels === "email"
                                ? [
                                    _c(
                                      "li",
                                      { staticClass: "list-group-item" },
                                      [
                                        _c("strong", [_vm._v("Viewed:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedItem.viewed_count
                                            )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      { staticClass: "list-group-item" },
                                      [
                                        _c("strong", [_vm._v("Clicked:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedItem.clicked_count
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm.selectedItem.channels === "push"
                                ? _c("li", { staticClass: "list-group-item" }, [
                                    _c("strong", [_vm._v("Notified:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedItem.notified_count
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.selectedItem.inbox
                                ? _c("li", { staticClass: "list-group-item" }, [
                                    _c("strong", [_vm._v("Read:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedItem.is_read_count)
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._l(
                          _vm.selectedItem.message,
                          function (message, key) {
                            return _c("CTab", { attrs: { title: key } }, [
                              _c("div", {
                                staticClass: "p-2",
                                domProps: { innerHTML: _vm._s(message) },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }