<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-chat-bubble" />
      <h5 class="d-inline ml-2">Message List</h5>
      <div class="card-header-actions">
        <CButton color="primary" :to="{ name: 'New Message' }">
          <CIcon name="cil-plus" size="lg" /><strong>New</strong> Message
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <div v-if="mounting" class="mb-4">
        <CSpinner color="primary" />
      </div>

      <div v-else>
        <!-- Filters -->
        <CCard class="filters" accent-color="warning">
          <CCardHeader
            role="button"
            class="text-warning shadow-none card-header"
            @click="resetFilters"
          >
            <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
            <div class="card-header-actions">
              <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
            </div>
          </CCardHeader>

          <CCollapse :show="$store.state.filterShow">
            <CCardBody class="p-2">
              <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
                <!-- Restaurant Filter -->
                <div class="flex-grow-1">
                  <v-select
                    class="v-select-filter"
                    placeholder="Select restaurant.."
                    v-model="selectedRestaurant"
                    :options="allRestaurants"
                    :loading="loading && allRestaurants.length <= 0"
                    @input="restaurantFilter"
                  />
                </div>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>

        <CDataTable
          class="table-messages"
          striped
          hover
          :items="loadedItems"
          :fields="fields"
          :sorter="{ external: true, resetable: false }"
          :itemsPerPageSelect="{
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          }"
          :items-per-page.sync="itemsPerPage"
          :loading="loading"
          :noItemsView="{
            noResults: 'No filtering results are available!',
            noItems: 'No messages found!',
          }"
          clickable-rows
          @row-clicked="rowClicked"
          @update:sorter-value="sorterValue"
          @pagination-change="paginationChange"
        >
          <!-- Title -->
          <template #title="{item}">
            <td>
              <strong>{{ item.title[defaultLang] }}</strong>
            </td>
          </template>

          <!-- Segment -->
          <template #segment="{item}">
            <td>
              <span>{{ item.segment }} ({{ item.users_count }})</span>
            </td>
          </template>

          <!-- Channels -->
          <template #channels="{item}">
            <td>
              <div class="d-flex flex-gap-1">
                <CBadge v-for="ch in item.channels.split(',')" color="light">
                  {{ ch }}
                </CBadge>
              </div>
            </td>
          </template>

          <!-- Voucher -->
          <template #voucher="{item}">
            <td>
              <div class="d-flex flex-column">
                <span>{{ item.voucher?.code }}</span>
                <cite class="small">{{ item.voucher?.name }}</cite>
              </div>
            </td>
          </template>

          <!-- Inbox -->
          <template #inbox="{item}">
            <td class="text-center">
              <span v-if="item.inbox">✅</span>
            </td>
          </template>

          <!-- Created at -->
          <template #created_at="{item}">
            <td>
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </td>
          </template>

          <!-- Scheduled time -->
          <template #scheduled_time="{item}">
            <td>
              <span v-if="item.scheduled_time">{{ new Date(item.scheduled_time).toLocaleString() }}</span>
            </td>
          </template>

          <!-- Actions -->
          <template #actions="{item, index}">
            <td>
              <CButton
                size="sm"
                color="info"
                shape="pill"
                v-c-tooltip="{
                  html: true,
                  content: 'Duplicate'
                }"
                @click="duplicate(item)"
              >
                <CIcon name="cil-clone" />
              </CButton>

              <template v-if="item.is_deletable === true">
                <CSpinner v-if="item.deleting" size="sm" color="danger" class="ml-3" />
                <CButton
                  v-else
                  class="ml-1"
                  size="sm"
                  color="danger"
                  variant="ghost"
                  shape="pill"
                  @click="deleteItem(item, index)"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </template>
            </td>
          </template>
        </CDataTable>
      </div>

      <CCard class="actions sticky-bottom">
        <CCardBody class="p-2">
          <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
            <div>
              <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
            </div>

            <div v-if="pages > 1" class="ml-auto">
              <CPagination
                align="center"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCardBody>

    <CModal
      class="modal-message"
      :title="modalTitle"
      :show.sync="showMessage"
      :closeOnBackdrop="false"
      size="lg"
      color="dark"
    >
      <template v-if="selectedItem">
        <CTabs v-if="showMessage" :active-tab.sync="activeTab">
          <CTab title="Stats">
            <ul class="msg-stats list-group list-group-flush">
              <li class="list-group-item"><strong>{{ selectedItem.segment }}:</strong> {{ selectedItem.users_count }}</li>
                <template v-if="selectedItem.channels === 'email'">
                <li class="list-group-item"><strong>Viewed:</strong> {{ selectedItem.viewed_count }}</li>
                <li class="list-group-item"><strong>Clicked:</strong> {{ selectedItem.clicked_count }}</li>
              </template>
              <li v-else-if="selectedItem.channels === 'push'" class="list-group-item">
                <strong>Notified:</strong> {{ selectedItem.notified_count }}
              </li>
              <li class="list-group-item" v-if="selectedItem.inbox"><strong>Read:</strong> {{ selectedItem.is_read_count }}</li>
            </ul>
          </CTab>

          <CTab v-for="(message, key) in selectedItem.message" :title="key">
            <div class="p-2" v-html="message"></div>
          </CTab>
        </CTabs>
      </template>

      <template #footer class="p-1">
        <CButton @click="showMessage = false" color="link">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: "MessageList",
  components: {
  },

  data() {
    return {
      selectedItem: null,
      loadedItems: [],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      defaultLang: "nl",
      modalTitle: null,
      loading: true,
      mounting: true,
      showMessage: false,
      activeTab: null,
    };
  },

  computed: {
    ...mapGetters('restaurants', ['allRestaurants']),

    fields() {
      return [
        { key: "title", label: `Title (${this.defaultLang})`, sorter: false, _style: "min-width: 200px;" },
        { key: "segment", sorter: false, _style: "min-width: 120px; width: 120px;" },
        { key: "channels", sorter: false, _style: "min-width: 120px; width: 180px;" },
        { key: "voucher", sorter: false, _style: "min-width: 120px; width: 140px;" },
        { key: "inbox", label: "Inbox", sorter: false, _style: "min-width: 76px; width: 76px; text-align: center;" },
        { key: "created_at", label: "Created", _style: "min-width: 140px; width: 140px;" },
        { key: "scheduled_time", label: "🕑 Scheduled", _style: "min-width: 140px; width: 140px;" },
        { key: "actions", label: "", sorter: false, _style: "min-width: 96px; width: 96px;" },
      ]
    },
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },

    selectedRestaurant: {
      get: function () { return this.$store.state.restaurants.selectedRestaurant },
      set: function (newValue) { this.$store.commit('restaurants/setSelectedRestaurant', newValue) }
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.getAllItems();
    },
    activeTab(index) {
      var keys = Object.keys(this.selectedItem?.message);
      this.modalTitle = this.selectedItem?.title[keys[index]];
    }
  },

  async mounted() {
    await this.fetchAllRestaurants();
    await this.getAllItems();
    this.mounting = false;

  },

  methods: {
    ...mapActions('restaurants', ['fetchAllRestaurants']),

    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.CRM_MESSAGES.GET_ALL),
        params = {
          paginate: true,
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          'order-by': this.orderBy,
          direction: this.direction,
          restaurant_id: this.selectedRestaurant?.value,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => response.data )
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if (items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                deleting: false
              };
            });
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    paginationChange(value) {
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    rowClicked(item, index, column, e) {
      const exclude = [
        "INPUT",
        "LABEL",
        "BUTTON",
        "svg",
        // "path",
        "A",
        // "IMG",
        // "SPAN",
        // "DIV",
        "rect",
        // "STRONG",
      ];

      if (!exclude.includes(e.target.tagName)) {
        var keys = Object.keys(item?.message);
        this.activeTab = keys.indexOf(this.defaultLang);
        this.modalTitle = item?.title[this.defaultLang];

        this.selectedItem = item;
        this.showMessage = true;
      }
    },

    duplicate(message) {
      var form = {
        // "channels": message.channels.split(','),
        "channels": message.channels.includes(',') ? message.channels.split(',')[0] : message.channels,
        "apps": message.push_apps || [],
        "segment": message.segment,
        "title": message.title,
        "message": message.message,
        "voucher": message.voucher,
        "put_inbox": message.inbox == 1,
        "active_until": message.active_until?.split(' ')[0],
        "data": {
          "restaurant_id": message.data?.restaurant_id || null,
          "custom_link": {
            "url": message.data?.custom_link?.url || null,
            "title": message.data?.custom_link?.title || null,
          },
          "in_app": message.data?.in_app || false,
          "disable_push_notification": message.data?.disable_push_notification || false,
        }
      };

      localStorage.setItem('user-message', JSON.stringify(form));
      this.$router.push({ name: "New Message" });
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The message will be deleted!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.CRM_MESSAGES.DELETE.replace("{id}", item.id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          index !== -1 && this.$delete(this.loadedItems, index);
          this.$toast.success(data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },

    // Filters
    restaurantFilter(value) {
      this.getAllItems();
    },
    async resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedRestaurant = null;
        this.getAllItems();
      }

      this.$store.commit('toggle', 'filterShow');
    },
  }
};
</script>

<style>
.table-messages td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}

.modal-message .modal-header {
  padding: 0.75rem !important;
}
.modal-message .modal-footer {
  padding: 0.25rem !important;
}

ul.msg-stats li {
  font-size: 1.375em;
  padding: 0.75rem 0.375rem;
}
</style>
